import { Form, Formik } from "formik"
import { FC, useContext, useState } from "react"
import { useQuery } from "urql"
import * as Yup from "yup"
import { WorkersCompCode, useUserEditMutation } from "../../../graphql/generated/client-types-and-hooks"
import { graphql } from "../../../graphql/generated/gql"
import { dataURLToBlob } from "../../../helpers/dataURLToBlob"
import { fileUpload } from "../../../helpers/fileUpload"
import { formatPhoneNumber } from "../../../helpers/formatPhoneNumber"
import { getDataUrlForImage } from "../../../helpers/getDataUrlForImage"
import { roleSelectOptions } from "../../../helpers/roleHelpers"
import { useHandleError } from "../../../hooks/useHandleError"
import { PermissionsContext } from "../../../providers/PermissionsProvider/PermissionsProvider"
import { ButtonFilled, ButtonHollow, Label } from "../../Elements"
import { ChipSelect } from "../../Formik/ChipSelect"
import { CreatableAutocomplete } from "../../Formik/CreatableAutocomplete"
import { StandardInput } from "../../Formik/StandardInput"
import { TextField } from "../../Formik/TextField"
import { WorkersCompCodeSelect } from "../../Formik/WorkersCompCodeSelect"
import { ImageUpload } from "../../ImageUpload"
import { errorSnack, successSnack } from "../../Notistack/ThemedSnackbars"
import { RenderIf } from "../../RenderIf"

type Values = {
  firstName: string
  lastName: string
  jobTitle: string
  email: string
  phoneNumber: string
  phoneNumberExt: string
  roleId: string
  workersCompCodeId: WorkersCompCode["id"]
  companyUserId: string
}

type Props = {
  onCancel: () => void
  onSuccess: () => void
  userId: string
}

export const EditUserFormQuery = graphql(`
  query EditUserFormQuery($id: String!, $fetchPay: Boolean!) {
    myOrganization {
      id
      roles {
        id
        name
        description
      }
    }
    getJobTitles
    user(id: $id) {
      id
      currentTaskId
      currentProjectId
      workersCompCodeId
      email
      firstName
      imageUrl
      jobTitle
      lastName
      phoneNumber
      phoneNumberExt
      companyUserId
      roles {
        id
        name
      }
      task {
        id
        name
      }
      payRate @include(if: $fetchPay)
      payType @include(if: $fetchPay)
    }
    usersList(status: "active") {
      id
      companyUserId
    }
  }
`)

const EditUserForm: FC<Props> = ({ userId, onCancel, onSuccess }) => {
  const { hasPermissionTo } = useContext(PermissionsContext)

  const [{ data }] = useQuery({
    query: EditUserFormQuery,
    variables: { id: userId!, fetchPay: hasPermissionTo("payRate:read") },
  })
  const user = data?.user
  const otherUsers = data?.usersList?.filter((u) => u.id !== userId) || []

  const [{ fetching: isLoading, error }, editUserMutation] = useUserEditMutation()
  useHandleError(error, "There was an error saving the user.")
  const [userImageSrc, setUserImageSrc] = useState<string>("")
  const [initialUserImageSrc, setInitialUserImageSrc] = useState<string>(user?.imageUrl || "")

  const onSubmit = async (values: Values) => {
    const { firstName, lastName, jobTitle, phoneNumber, phoneNumberExt, roleId, workersCompCodeId, companyUserId } =
      values

    let image = !initialUserImageSrc ? "" : undefined
    if (userImageSrc) {
      const imageBlob = dataURLToBlob(userImageSrc)
      const uploadedFile = await fileUpload(`user-image/${user?.id}`, "image/webp", imageBlob)
      image = uploadedFile?.objectKey || undefined
    }

    editUserMutation({
      id: user?.id!,
      firstName,
      lastName,
      jobTitle,
      phoneNumber,
      phoneNumberExt,
      task: user?.currentTaskId!,
      roles: [roleId],
      workersCompCodeId: workersCompCodeId !== "" ? workersCompCodeId : undefined,
      companyUserId: companyUserId !== "" ? companyUserId : null,
      image,
    }).then((result) => {
      if (result.error) {
        errorSnack("Unexpected error; please try again")
        console.error(result.error)
      } else {
        successSnack("User update complete")
        onSuccess()
        setUserImageSrc("")
      }
    })
  }

  return (
    <>
      {user?.id && data?.myOrganization && (
        <Formik
          initialValues={{
            firstName: user.firstName,
            lastName: user.lastName,
            jobTitle: user.jobTitle,
            email: user.email,
            phoneNumber: formatPhoneNumber(user.phoneNumber || ""),
            phoneNumberExt: user.phoneNumberExt || "",
            roleId: user.roles[0].id,
            workersCompCodeId: user.workersCompCodeId || "",
            companyUserId: user.companyUserId || "",
          }}
          validationSchema={Yup.object().shape({
            firstName: Yup.string().trim().required("Required").label("First Name"),
            lastName: Yup.string().trim().required("Required").label("Last Name"),
            jobTitle: Yup.string().trim().nullable().label("Title"),
            roleId: Yup.string().required(),
            phoneNumber: Yup.string()
              .trim()
              .nullable()
              .label("Phone")
              .matches(/\(([2-9])(\d{2})\) (\d{3})-(\d{4})$/, { message: "Invalid phone number format" }),
            phoneNumberExt: Yup.string()
              .trim()
              .nullable()
              .label("Ext.")
              .matches(/(^[0-9]+)$/, { message: "Invalid number format" }),
            workersCompCodeId: Yup.string().nullable().label("Workers Comp Code"),
            companyUserId: Yup.string()
              .trim()
              .label("Company user ID")
              .nullable()
              .test("isUnique", "There is another user with this ID", (companyUserId) =>
                otherUsers.every((u) => u?.companyUserId !== companyUserId)
              ),
          })}
          onSubmit={onSubmit}
        >
          {() => (
            <Form className="grid grid-cols-1 gap-y-2 transition-all">
              <div className="grid gap-2">
                <Label htmlFor="image">image</Label>
                <ImageUpload
                  onFileSelected={([file]) => {
                    if (file) {
                      getDataUrlForImage(file, (dataUrl) => setUserImageSrc(dataUrl), 600, 600)
                    }
                  }}
                  onDelete={() => {
                    setUserImageSrc("")
                    setInitialUserImageSrc("")
                  }}
                  imageSrc={userImageSrc}
                  initialImageSrc={initialUserImageSrc}
                  accept={"accept"}
                />
              </div>
              <div className="grid lg:grid-cols-2 gap-y-1.5 lg:space-x-3">
                <StandardInput name="firstName" label="First Name" required={true} />
                <StandardInput name="lastName" label="Last Name" required={true} />
              </div>
              <CreatableAutocomplete
                name="jobTitle"
                label="Job Title"
                disabled={isLoading}
                defaultValue={user.jobTitle}
                options={(data?.getJobTitles || []).map((title) => ({ label: title, id: title }))}
              />
              <StandardInput
                name="email"
                label="Email"
                required={true}
                disabled
                type={"email"}
                title={"You cannot edit a user's email"}
              />
              <div className="grid grid-cols-3 gap-y-1.5 gap-x-3">
                <div className="col-span-2">
                  <StandardInput
                    name="phoneNumber"
                    label="Phone"
                    required={false}
                    type={"tel"}
                    placeholder={"(888) 888-8888"}
                  />
                </div>
                <div className="col-span-1">
                  <StandardInput
                    name="phoneNumberExt"
                    label="Ext."
                    required={false}
                    type={"number"}
                    placeholder={""}
                    min={0}
                  />
                </div>
              </div>

              <RenderIf permissionsInclude={"timeEntry:export"}>
                <div className="pb-5">
                  <Label isRequired={false}>Workers Comp Code</Label>
                  <WorkersCompCodeSelect name="workersCompCodeId" />
                </div>
              </RenderIf>

              <div className="grid grid-cols-3 gap-y-1.5 gap-x-3">
                <div className="col-span-2">
                  <TextField
                    name="companyUserId"
                    label="Company User ID"
                    required={false}
                    type={"text"}
                    placeholder={"Company User ID"}
                  />
                </div>
              </div>
              <ChipSelect
                required
                name="roleId"
                label="App Role"
                options={roleSelectOptions(data?.myOrganization.roles)}
              />
              <div className="pt-4 flex flex-wrap items-center gap-x-8 gap-y-3">
                <ButtonFilled type="submit" disabled={isLoading}>
                  Save changes
                </ButtonFilled>
                <ButtonHollow type="button" onClick={onCancel}>
                  Cancel
                </ButtonHollow>
              </div>
            </Form>
          )}
        </Formik>
      )}
    </>
  )
}
export default EditUserForm
