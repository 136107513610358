import { Divider, Skeleton, Typography } from "@mui/material"
import { Form, Formik } from "formik"
import { FC, useContext, useEffect, useState } from "react"
import { useQuery } from "urql"
import * as Yup from "yup"
import { PayType, WorkersCompCode, useUserEditMutation } from "../../../graphql/generated/client-types-and-hooks"
import { dataURLToBlob } from "../../../helpers/dataURLToBlob"
import { fileUpload } from "../../../helpers/fileUpload"
import { formatPhoneNumber } from "../../../helpers/formatPhoneNumber"
import { getDataUrlForImage } from "../../../helpers/getDataUrlForImage"
import { getFullName } from "../../../helpers/getFullName"
import { roleSelectOptions } from "../../../helpers/roleHelpers"
import { useHandleError } from "../../../hooks/useHandleError"
import { PermissionsContext } from "../../../providers/PermissionsProvider/PermissionsProvider"
import { DevelopmentFeatureFlag } from "../../DevelopmentFeatureFlag"
import { ButtonFilled, ButtonHollow, H2, H5 } from "../../Elements"
import { FormRow } from "../../FormRow"
import { ChipSelect } from "../../Formik/ChipSelect"
import { CreatableAutocomplete } from "../../Formik/CreatableAutocomplete"
import { RadioGroup } from "../../Formik/RadioGroup"
import { TextField } from "../../Formik/TextField"
import { WorkersCompCodeSelect } from "../../Formik/WorkersCompCodeSelect"
import { ImageUpload } from "../../ImageUpload2"
import { errorSnack, successSnack } from "../../Notistack/ThemedSnackbars"
import { PageTitle } from "../../PageTitle"
import { RenderIf } from "../../RenderIf"
import { EditUserFormQuery } from "../User/EditUserForm"
import { SingleDrawerContext } from "./Drawer"
import { DrawerBody } from "./DrawerBody"
import { DrawerFooter } from "./DrawerFooter"
import { DrawerHeader } from "./DrawerHeader"

type Values = {
  firstName: string
  lastName: string
  jobTitle: string
  email: string
  phoneNumber: string
  phoneNumberExt: string
  roleId: string
  workersCompCodeId: WorkersCompCode["id"]
  companyUserId: string
  payType?: PayType
  payRate?: string
}

type Props = {
  userId: string
}

const UserEditDrawer: FC<Props> = ({ userId }) => {
  const { handleClose } = useContext(SingleDrawerContext)
  const { hasPermissionTo } = useContext(PermissionsContext)
  const [{ data, fetching: fetchingUsers }] = useQuery({
    query: EditUserFormQuery,
    variables: { id: userId!, fetchPay: hasPermissionTo("payRate:read") },
  })
  const user = data?.user

  const otherUsers = data?.usersList?.filter((u) => u.id !== userId) || []

  const [{ fetching: fetchingMutation, error }, editUserMutation] = useUserEditMutation()
  useHandleError(error, "There was an error saving the user.")
  const [userImageSrc, setUserImageSrc] = useState<string>("")
  const [initialUserImageSrc, setInitialUserImageSrc] = useState<string>("")

  useEffect(() => {
    // Set initial user image after user is loaded
    setInitialUserImageSrc(user?.imageUrl || "")
  }, [user?.imageUrl])

  const onSubmit = async (values: Values) => {
    const {
      firstName,
      lastName,
      jobTitle,
      phoneNumber,
      phoneNumberExt,
      roleId,
      workersCompCodeId,
      companyUserId,
      payType,
      payRate,
    } = values

    let image = !initialUserImageSrc ? "" : undefined
    if (userImageSrc) {
      const imageBlob = dataURLToBlob(userImageSrc)
      const uploadedFile = await fileUpload(`user-image/${user?.id}`, "image/webp", imageBlob)
      image = uploadedFile?.objectKey || undefined
    }

    editUserMutation({
      id: user?.id!,
      firstName,
      lastName,
      jobTitle,
      phoneNumber,
      phoneNumberExt,
      task: user?.currentTaskId!,
      roles: [roleId],
      workersCompCodeId: workersCompCodeId !== "" ? workersCompCodeId : undefined,
      companyUserId: companyUserId !== "" ? companyUserId : null,
      image,
      payType: payType as PayType,
      payRate: payRate?.replace(/[^0-9.]/g, ""),
    }).then((result) => {
      if (result.error) {
        errorSnack("Unexpected error; please try again")
        console.error(result.error)
      } else {
        successSnack("User update complete")
        setUserImageSrc("")
        handleClose()
      }
    })
  }

  if (fetchingUsers || fetchingMutation) {
    return <Skeleton />
  }

  return (
    <>
      {user?.id && data?.myOrganization && (
        <Formik
          initialValues={{
            firstName: user.firstName,
            lastName: user.lastName,
            jobTitle: user.jobTitle,
            email: user.email,
            phoneNumber: formatPhoneNumber(user.phoneNumber || ""),
            phoneNumberExt: user.phoneNumberExt || "",
            roleId: user.roles[0].id,
            workersCompCodeId: user.workersCompCodeId || "",
            companyUserId: user.companyUserId || "",
            payType: user.payType || PayType.Hourly,
            payRate: user.payRate || "",
          }}
          validationSchema={Yup.object().shape({
            firstName: Yup.string().trim().required("Required").label("First Name"),
            lastName: Yup.string().trim().required("Required").label("Last Name"),
            jobTitle: Yup.string().trim().nullable().label("Title"),
            roleId: Yup.string().required(),
            phoneNumber: Yup.string()
              .trim()
              .nullable()
              .label("Phone")
              .matches(/\(([2-9])(\d{2})\) (\d{3})-(\d{4})$/, { message: "Invalid phone number format" }),
            phoneNumberExt: Yup.string()
              .trim()
              .nullable()
              .label("Ext.")
              .matches(/(^[0-9]+)$/, { message: "Invalid number format" }),
            workersCompCodeId: Yup.string().nullable().label("Workers Comp Code"),
            companyUserId: Yup.string()
              .trim()
              .label("Company user ID")
              .nullable()
              .test("isUnique", "There is another user with this ID", (companyUserId) =>
                otherUsers.every((u) => u?.companyUserId !== companyUserId)
              ),
            payType: Yup.mixed<PayType>().oneOf(Object.values(PayType), "Invalid Pay Type").required(),
            payRate: Yup.string().trim().nullable().label("Pay Rate"),
          })}
          onSubmit={onSubmit}
        >
          {({ isSubmitting }) => (
            <Form>
              <DrawerHeader handleClose={handleClose} href={`/team/${userId}/details`} />

              <DrawerBody>
                <H2 className="mb-6 text-gray-800">Edit User</H2>
                <PageTitle title={!user?.firstName ? "User details" : `${getFullName(user)} details`} />

                <H5 className="mt-10 mb-0">Basic Info</H5>
                <hr className="mt-2 mb-4 md:mb-6" />

                <FormRow childrenContainerClassName="flex-col mb-6">
                  <ImageUpload
                    onFileSelected={([file]) => {
                      if (file) {
                        getDataUrlForImage(file, (dataUrl) => setUserImageSrc(dataUrl), 600, 600)
                      }
                    }}
                    onDelete={() => {
                      setUserImageSrc("")
                      setInitialUserImageSrc("")
                    }}
                    imageSrc={userImageSrc}
                    initialImageSrc={initialUserImageSrc}
                    accept="accept"
                  />
                </FormRow>

                <FormRow childrenContainerClassName="flex-col">
                  <div className="grid lg:grid-cols-2 gap-y-1.5 lg:space-x-3 mt-5">
                    <TextField name="firstName" label="First name" />
                    <TextField name="lastName" label="Last name" />
                  </div>
                </FormRow>

                <FormRow>
                  <CreatableAutocomplete
                    fullWidth
                    name="jobTitle"
                    label="Job title"
                    defaultValue={user.jobTitle}
                    options={(data?.getJobTitles || []).map((title) => ({ label: title, id: title }))}
                  />
                </FormRow>

                <FormRow>
                  <TextField
                    fullWidth
                    name="email"
                    label="Email"
                    disabled
                    type="email"
                    title="You cannot edit a user's email"
                  />
                </FormRow>

                <FormRow childrenContainerClassName="flex-col">
                  <div className="grid grid-cols-3 gap-y-1.5 gap-x-3">
                    <div className="col-span-2">
                      <TextField
                        fullWidth
                        name="phoneNumber"
                        label="Phone number"
                        type="tel"
                        placeholder="(888) 888-8888"
                      />
                    </div>
                    <div className="col-span-1">
                      <TextField
                        fullWidth
                        name="phoneNumberExt"
                        label="Ext"
                        type="number"
                        InputProps={{ inputProps: { min: 0 } }}
                      />
                    </div>
                  </div>
                </FormRow>

                <FormRow childrenContainerClassName="flex-col">
                  <RenderIf permissionsInclude="timeEntry:export">
                    <div className="pb-5">
                      <WorkersCompCodeSelect name="workersCompCodeId" />
                    </div>
                  </RenderIf>
                </FormRow>

                <FormRow>
                  <TextField fullWidth name="companyUserId" type="text" label="Company User ID" />
                </FormRow>
                <DevelopmentFeatureFlag name="Pay Rate">
                  <RenderIf permissionsInclude="payRate:update">
                    <FormRow childrenContainerClassName="flex-col">
                      <Typography variant="h5">Payment Details</Typography>
                      <Divider className="mb-5" />

                      <div className="flex items-center gap-3 mb-3">
                        <Typography className="font-semibold text-gray-800">Pay type</Typography>
                        <RadioGroup
                          name="payType"
                          options={[
                            { label: "Hourly", value: PayType.Hourly },
                            { label: "Salary", value: PayType.Salary },
                          ]}
                        />
                      </div>
                      <TextField fullWidth name="payRate" label="Pay Rate" type="money" />
                    </FormRow>
                  </RenderIf>
                </DevelopmentFeatureFlag>

                <ChipSelect
                  required
                  name="roleId"
                  options={roleSelectOptions(data?.myOrganization.roles)}
                  labelEl={
                    <>
                      <H5 className="mt-10 mb-0">App Role & Permissions</H5>
                      <hr className="mt-2 mb-4 md:mb-6" />
                    </>
                  }
                />

                <DrawerFooter>
                  <div className="pt-4 flex flex-wrap items-center gap-x-8 gap-y-3">
                    <ButtonFilled type="submit" disabled={isSubmitting}>
                      {isSubmitting ? "Saving" : "Update"}
                    </ButtonFilled>
                    <ButtonHollow type="button" onClick={handleClose}>
                      Cancel
                    </ButtonHollow>
                  </div>
                </DrawerFooter>
              </DrawerBody>
            </Form>
          )}
        </Formik>
      )}
    </>
  )
}
export default UserEditDrawer
