import { FC } from "react"
import { SkeletonContainer } from "../../Skeletons/SkeletonContainer"
import { SkeletonElement } from "../../Skeletons/SkeletonElement"
import { SkeletonH2 } from "../../Skeletons/SkeletonH2"
import { SkeletonCard } from "../../Skeletons/SkeletonCard"

export const UserSkeleton: FC = () => {
  return (
    <SkeletonContainer>
      <div className={"flex gap-x-4 mb-12"}>
        <SkeletonElement className={"h-20 w-20"} />
        <div className={"md:ml-4 flex flex-col justify-center"}>
          <SkeletonH2 />
          <SkeletonElement className={"h-4 w-32"} />
        </div>
      </div>
      <div className={"flex gap-6 mb-12"}>
        <SkeletonElement className={"h-6 w-16"} />
        <SkeletonElement className={"h-6 w-28"} />
      </div>
      <div className="flex flex-col gap-9">
        <SkeletonCard className={"h-96"} />
        <SkeletonCard className={"h-96"} />
      </div>
    </SkeletonContainer>
  )
}
