type Field = { [key: string]: any }

export const sortByFieldAscending = (callback: Function) => (a: Field, b: Field) => {
  const aValue = callback(a)
  const bValue = callback(b)

  if (aValue > bValue) return 1
  if (aValue < bValue) return -1

  return 0
}
