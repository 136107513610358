import { DataGridPro, GridRenderCellParams } from "@mui/x-data-grid-pro"
import { NextPage } from "next"
import Error from "next/error"
import { FC, MutableRefObject } from "react"
import { useQuery } from "urql"
import { H4 } from "../../../components/Elements"
import { LoadingIndicator } from "../../../components/Loading/LoadingIndicator"
import { PageTitle } from "../../../components/PageTitle"
import { UserInjuryReports } from "../../../components/Partials/InjuryReports/InjuryReports"
import { RenderIf } from "../../../components/RenderIf"
import { UserOfflineEventsQuery } from "../../../graphql/generated/client-types-and-hooks"
import { graphql } from "../../../graphql/generated/gql"
import { getFullName } from "../../../helpers/getFullName"
import UserPageLayout from "./_layout"

const UserOfflineEventsDocument = graphql(`
  query UserOfflineEvents($userId: String!) {
    user(id: $userId) {
      id
      firstName
      lastName
      offlineEvents {
        id
        reconciledAt
        receivedAt
        clientCreatedAt
        clientCanceledAt
        # location {
        #   latitude
        #   longitude
        #   accuracy
        # }
        issues

        clockInData {
          taskId
          task {
            id
            name
          }
          user {
            id
            fullName
          }
        }
        clockOutData {
          task {
            id
            name
          }
          user {
            id
            fullName
          }
        }
        # taskProgressData
        # assetReportData

        type
      }
    }
  }
`)

export const UserPageOfflineEvents: FC<{ userId: string; drawerRef?: MutableRefObject<null> }> = ({ userId }) => {
  const [{ data, fetching }, _refetch] = useQuery({ query: UserOfflineEventsDocument, variables: { userId } })

  const user = data?.user

  if (fetching || !user) return <LoadingIndicator />

  return (
    <>
      <PageTitle
        title={
          !user?.firstName && !user?.lastName
            ? "User offline event history"
            : `${getFullName(user)} offline event history`
        }
      />
      <div className="grid md:grid-cols-3 grid-cols-1 gap-4 mb-4">
        <H4 className="mb-2 md:col-span-2 grid-col-span-1">Offline Event History</H4>
        <div className="pb-3 grid justify-start md:justify-end"></div>
      </div>
      <div>
        <UserInjuryReports userId={userId} />
      </div>
      <div className="w-full">
        <DataGridPro
          columns={[
            { field: "clientCreatedAt", headerName: "Saved At", type: "dateTime" },
            { field: "receivedAt", headerName: "Received At", type: "dateTime" },
            { field: "reconciledAt", headerName: "Reconciled", type: "dateTime" },
            { field: "issues", headerName: "Issues" },
            { field: "type", headerName: "Type" },
            {
              field: "data",
              headerName: "Data",
              renderCell: ({ row }: GridRenderCellParams<UserOfflineEventsQuery["user"]["offlineEvents"][0]>) => {
                const data = row.clockInData || row.clockOutData
                if (!data) return "--"
                return (
                  <div className="grid">
                    <div>{`User: ${data.user?.fullName || ""}`}</div>
                    <div>{`Task: ${data.task?.name || ""}`}</div>
                  </div>
                )
              },
            },
          ].map((col) => ({ flex: 1, ...col }))}
          rows={user.offlineEvents}
        />
      </div>
    </>
  )
}

const UserPage: NextPage<{ userId: string }> = ({ userId }) => {
  return (
    <RenderIf permissionsInclude="user:read" fallbackComponent={<Error statusCode={404} />}>
      <UserPageLayout userId={userId}>
        <UserPageOfflineEvents userId={userId} />
      </UserPageLayout>
    </RenderIf>
  )
}

UserPage.getInitialProps = async ({ query }) => {
  return {
    userId: query._id as string,
  }
}

export default UserPage
