import { NextPage } from "next"
import { createContext, FC, ReactNode, useContext, useEffect } from "react"
import { BiDotsHorizontalRounded, BiEdit, BiLabel, BiTrash } from "react-icons/bi"
import { useQuery } from "urql"
import { DeprecatedTabGroup, Tab } from "../../../components/deprecated/TabGroup"
import { H2 } from "../../../components/Elements"
import Layout from "../../../components/Layout/Layout"
import { MonogramRing } from "../../../components/Monogram"
import { errorSnack, successSnack } from "../../../components/Notistack/ThemedSnackbars"
import UserEditDrawer from "../../../components/Partials/Drawer/UserEditDrawer"
import { UserSkeleton } from "../../../components/Partials/User/User.skeleton"
import { MenuItem, QuickMenuMui } from "../../../components/QuickMenuMui"
import { teamMemberProfilePageTestSelectors } from "../../../cypress/support/testSelectors"
import {
  TeamMemberPageQuery,
  useUserDeleteMutation,
  useUserRestoreMutation,
} from "../../../graphql/generated/client-types-and-hooks"
import { graphql } from "../../../graphql/generated/gql"
import { BreadcrumbsContext } from "../../../providers/BreadcrumbsProvider"
import { DrawerContext } from "../../../providers/DrawerProvider"
import { PermissionsContext } from "../../../providers/PermissionsProvider/PermissionsProvider"

export const tabs: Tab[] = [
  {
    label: "Details",
    href: "/team/[_id]/details",
    testSelector: teamMemberProfilePageTestSelectors.tabs.details,
  },
  {
    label: "Assets",
    href: "/team/[_id]/assets",
    testSelector: teamMemberProfilePageTestSelectors.tabs.assets,
  },
  {
    label: "Time Cards",
    href: "/team/[_id]/time-cards",
    testSelector: teamMemberProfilePageTestSelectors.tabs.timeCards,
  },
  {
    label: "Documents",
    href: "/team/[_id]/documents",
    testSelector: teamMemberProfilePageTestSelectors.tabs.documents,
  },
  {
    label: "Offline Events",
    href: "/team/[_id]/offline-events",
    requiredFeatureFlag: "Show Offline Events for User",
    testSelector: teamMemberProfilePageTestSelectors.tabs.offlineEvents,
  },
]

type Props = {
  children: ReactNode
  userId: string
  withTabs?: boolean
}

type UserLayoutContextType = {
  user: TeamMemberPageQuery["user"]
}

export const UserLayoutContext = createContext<UserLayoutContextType>({
  user: {} as TeamMemberPageQuery["user"],
})

const TeamMemberPageQueryDocument = graphql(`
  query TeamMemberPage($id: String!) {
    user(id: $id) {
      id
      firstName
      imageUrl
      jobTitle
      lastName
      archived
      isClockedIn
    }
  }
`)

export const TeamMemberPageContent: FC<Props> = ({ children, userId, withTabs = true }) => {
  const { setLabelSubstitutions, setIsLoading } = useContext(BreadcrumbsContext)
  const { push: pushDrawer, pop: closeDrawer } = useContext(DrawerContext)
  const { hasPermissionTo } = useContext(PermissionsContext)
  const [{ fetching, data }] = useQuery({ query: TeamMemberPageQueryDocument, variables: { id: userId } })
  const [, deleteUserMutation] = useUserDeleteMutation()
  const [, restoreUserMutation] = useUserRestoreMutation()

  const user = data?.user
  useEffect(() => {
    setIsLoading(fetching)
    setLabelSubstitutions([
      {
        original: userId,
        replacement: `${user?.firstName} ${user?.lastName}`,
      },
    ])
  }, [fetching, setIsLoading, setLabelSubstitutions, user?.firstName, user?.lastName, userId])

  const menuItems: MenuItem[][] = []

  if (hasPermissionTo("user:update")) {
    menuItems.push([
      {
        value: "Edit",
        onClick: () => pushDrawer(<UserEditDrawer userId={userId} />),
        Icon: BiEdit,
      },
    ])
  }

  if (hasPermissionTo("user:archive") && !user?.archived) {
    menuItems.push([
      {
        value: "Archive",
        onClick: () => {
          // User is clocked out by this action, in the backend, where it matters
          deleteUserMutation({ id: userId }).then((result) => {
            result.error ? errorSnack("Error archiving user") : successSnack("User archived successfully")
            closeDrawer()
          })
        },
        color: "red",
        Icon: BiTrash,
      },
    ])
  }

  if (hasPermissionTo("user:archive") && user?.archived) {
    menuItems.push([
      {
        value: "Unarchive",
        onClick: () => {
          restoreUserMutation({ id: user.id }).then((result) =>
            result.error ? errorSnack("Error unarchiving user") : successSnack("User unarchived successfully")
          )
        },
        color: "red",
        Icon: BiTrash,
      },
    ])
  }
  if (!user) return <UserSkeleton />

  return (
    <UserLayoutContext.Provider value={{ user }}>
      <div>
        {fetching && <UserSkeleton />}
        {!fetching && user && (
          <>
            <div className="flex mb-4 gap-x-4">
              <MonogramRing
                user={user}
                textSize="text-2xl"
                ringWidth="w-20"
                ringHeight="h-20"
                ringBorder="border-4"
                width="w-16"
                height="h-16"
              />
              <div className="md:ml-4 flex flex-col justify-center gap-2">
                <div className="flex gap-x-2">
                  <H2 className="text-gray-800 my-0">{user.firstName + " " + user.lastName}</H2>
                  <QuickMenuMui className="self-center" items={menuItems} buttonShape="round">
                    <BiDotsHorizontalRounded className="text-2xl" />
                  </QuickMenuMui>
                </div>
                <div className="flex gap-0.5 text-gray-600 items-center">
                  <BiLabel className="w-4 h-4" />
                  <span>{user.jobTitle}</span>
                </div>
              </div>
            </div>
            {withTabs && <DeprecatedTabGroup tabs={tabs} />}
            {children}
          </>
        )}
      </div>
    </UserLayoutContext.Provider>
  )
}

const UserPageLayout: NextPage<Props> = ({ children, userId }) => {
  return (
    <Layout>
      <TeamMemberPageContent userId={userId}>{children}</TeamMemberPageContent>
    </Layout>
  )
}

export default UserPageLayout
