import { Accordion, AccordionDetails, AccordionSummary, Box, Grid, Typography } from "@mui/material"
import { grey, orange } from "@mui/material/colors"
import { DataGridPro, GRID_DETAIL_PANEL_TOGGLE_COL_DEF, GridColDef, GridRowParams } from "@mui/x-data-grid-pro"
import { format } from "date-fns"
import { FC } from "react"
import { BiChevronDown, BiHealth } from "react-icons/bi"
import { useQuery } from "urql"
import { InjuryReport, User } from "../../../graphql/generated/client-types-and-hooks"
import { graphql } from "../../../graphql/generated/gql"
import { PickPlus } from "../../../types/helpers"
import { LabeledSection } from "../../LabeledSection"
import { SkeletonBlock } from "../../Skeletons/SkeletonBlock"
import { CustomDetailPanelToggle } from "../DataGrid/CustomDetailPanelToggle"

/*
  Specific components which render the underlying table after making their specific queries

  - UserInjuryReports
  - ProjectInjuryReports
  - TaskInjuryReports
*/

const UserInjuryReportsTableDocument = graphql(`
  query UserInjuryReports($id: String!) {
    user(id: $id) {
      id
      injuryReports {
        id
        createdAt
        notes

        reporter {
          id
          fullName
        }
        user {
          id
          fullName
        }
      }
    }
  }
`)

export const UserInjuryReports: FC<{ userId: string }> = ({ userId }) => {
  const [{ data }] = useQuery({
    query: UserInjuryReportsTableDocument,
    variables: { id: userId },
  })

  if (!data || data?.user?.injuryReports?.length === 0) return null

  return <InjuryReports injuryReports={data?.user?.injuryReports || []} />
}

const ProjectInjuryReportsTableDocument = graphql(`
  query ProjectInjuryReports($id: String!) {
    project(id: $id) {
      id
      injuryReports {
        id
        createdAt
        notes

        reporter {
          id
          fullName
        }
        user {
          id
          fullName
        }
      }
    }
  }
`)

export const ProjectInjuryReports: FC<{ projectId: string }> = ({ projectId }) => {
  const [{ data }] = useQuery({
    query: ProjectInjuryReportsTableDocument,
    variables: { id: projectId },
  })

  if (!data) {
    return <SkeletonBlock className="h-12 w-full" />
  }

  return (
    <div className="grid">
      {data?.project?.injuryReports?.length === 0 ? (
        <div className="py-4 place-self-center text-gray-400 m-4 ">No injury reports</div>
      ) : (
        <InjuryReports injuryReports={data?.project?.injuryReports || []} />
      )}
    </div>
  )
}

const TaskInjuryReportsTableDocument = graphql(`
  query TaskInjuryReports($id: String!) {
    task(id: $id) {
      id
      injuryReports {
        id
        createdAt
        notes

        reporter {
          id
          fullName
        }
        user {
          id
          fullName
        }
      }
    }
  }
`)

export const TaskInjuryReports: FC<{ taskId: string }> = ({ taskId }) => {
  const [{ data }] = useQuery({
    query: TaskInjuryReportsTableDocument,
    variables: { id: taskId },
  })

  if (!data) {
    return <SkeletonBlock className="h-12 w-full" />
  }

  return (
    <div className="grid">
      {data?.task?.injuryReports?.length === 0 ? (
        <div className="py-4 place-self-center text-gray-400 m-4 ">No injury reports</div>
      ) : (
        <InjuryReports injuryReports={data?.task?.injuryReports || []} />
      )}
    </div>
  )
}

// Base TABLE implementation
type InjuryReportUserReference = PickPlus<User, "id" | "fullName">
type InjuryReportRenderable = PickPlus<InjuryReport, "id" | "createdAt"> & {
  reporter: InjuryReportUserReference
  user: InjuryReportUserReference
}

const defaultColumnProps: Pick<GridColDef, "disableColumnMenu" | "editable" | "filterable" | "sortable" | "flex"> = {
  editable: false,
  disableColumnMenu: true,
  filterable: false,
  sortable: false,
  flex: 1,
}

export const InjuryReports: FC<{ injuryReports: InjuryReportRenderable[] }> = ({ injuryReports }) => {
  return (
    <>
      <Accordion sx={{ border: "1px solid #E0E0E0" }}>
        <AccordionSummary expandIcon={<BiChevronDown className="w-6 h-6" />}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Box display="flex">
                <BiHealth color={orange[600]} className="w-5 h-5 mr-1 relative top-[2px]" />
                <Typography color={orange[600]}>Injury Reports</Typography>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Typography color={grey[400]}>{injuryReports.length} Reports</Typography>
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails className="pb-0">
          <DataGridPro
            hideFooter
            columns={[
              {
                ...defaultColumnProps,
                field: "date",
                headerName: "Date",
                valueGetter: ({ row }: { row: InjuryReportRenderable }) => format(row.createdAt, "EEE, LLL do "),
              },
              {
                ...defaultColumnProps,
                field: "time",
                headerName: "Time",
                valueGetter: ({ row }: { row: InjuryReportRenderable }) => format(row.createdAt, "h:mm aaa"),
              },
              {
                ...defaultColumnProps,
                field: "user",
                headerName: "Individual",
                valueGetter: ({ row }: { row: InjuryReportRenderable }) => row.user.fullName,
              },
              {
                ...defaultColumnProps,
                field: "reportedBy",
                headerName: "Reported By",
                valueGetter: ({ row }: { row: InjuryReportRenderable }) => row.reporter.fullName,
              },
              {
                ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
                width: 25,
                renderCell: ({ id, value }) => <CustomDetailPanelToggle id={id} value={value} />,
              },
            ]}
            getDetailPanelContent={getDetailPanelContent}
            getDetailPanelHeight={getDetailPanelHeight}
            rows={injuryReports}
            sx={{
              border: "none",
              fontSize: "16px",
              "& .MuiDataGrid-detailPanel .MuiDataGrid-row": { border: "none" },
              "& .MuiDataGrid-cell:focus": { outline: "none" },
              "& .MuiDataGrid-cell:focus-within": { outline: "none" },
              "& .MuiDataGrid-columnHeader:focus": { outline: "none" },
              "& .MuiDataGrid-columnHeader:focus-within": { outline: "none" },
              "& .MuiDataGrid-pinnedColumnHeaders": { display: "none" },
              "& .MuiDataGrid-pinnedColumns": { boxShadow: "none" },
            }}
          />
        </AccordionDetails>
      </Accordion>
    </>
  )
}

const getDetailPanelContent = (params: GridRowParams<InjuryReport>) => {
  return (
    <div className="inline-block mb-4">
      <LabeledSection
        className="p-4 m-2 bg-yellow-100 shadow-md flex-column border-gray-200 rounded-md flex-grow flex-shrink inline-block"
        label="Injury report note"
      >
        <span>{params.row.notes}</span>
      </LabeledSection>
    </div>
  )
}

const getDetailPanelHeight = () => "auto" as "auto"
